import { Flex, Skeleton as MantineSkeleton, SkeletonProps, Space } from '@mantine/core'

import { useScreenSize } from '~/hooks'

const Skeleton = (props: SkeletonProps) => (
  <MantineSkeleton
    sx={{ borderRadius: 18 }}
    {...props}
  />
)

export const GuideCardPlaceholder = () => {
  const { isMobileScreen } = useScreenSize()
  const imageWidth = isMobileScreen ? 120 : 170
  const imageHeight = isMobileScreen ? 160 : 224

  if (isMobileScreen) {
    return (
      <Flex
        direction="column"
        gap={19}
      >
        <Flex
          justify="space-between"
          gap={13}
        >
          <Flex
            w="100%"
            direction="column"
            gap={16}
          >
            <Flex gap={8}>
              <Skeleton
                width={32}
                height={32}
                circle
              />
              <Flex
                direction="column"
                justify="space-between"
              >
                <Skeleton
                  height={12}
                  width={110}
                />
                <Skeleton
                  height={12}
                  width={86}
                />
              </Flex>
            </Flex>

            <Flex
              direction="column"
              gap={6}
              mb={2}
            >
              <Skeleton
                height={15}
                width={180}
              />

              <Skeleton
                height={15}
                width={208}
              />
            </Flex>

            <Flex
              direction="column"
              gap={5}
            >
              <Skeleton
                height={12}
                width={194}
              />
              <Skeleton
                height={12}
                width={202}
              />
            </Flex>

            <Flex gap={5}>
              <Skeleton
                height={10}
                width={48}
              />
              <Skeleton
                height={10}
                width={52}
              />
              <Skeleton
                height={10}
                width={41}
              />
              <Skeleton
                height={10}
                width={44}
              />
            </Flex>
          </Flex>

          <MantineSkeleton
            width={imageWidth}
            height={imageHeight}
            miw={imageWidth}
            mih={imageHeight}
          />
        </Flex>
        <Flex
          justify="space-between"
          align="center"
        >
          <Skeleton
            height={16}
            width={99}
          />

          <Skeleton
            height={16}
            width={50}
          />
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex
      justify="space-between"
      gap={24}
    >
      <Flex
        w="100%"
        direction="column"
        gap={16}
      >
        <Flex gap={8}>
          <Skeleton
            width={32}
            height={32}
            circle
          />
          <Flex
            direction="column"
            justify="space-between"
          >
            <Skeleton
              height={12}
              width={140}
            />
            <Skeleton
              height={12}
              width={100}
            />
          </Flex>
        </Flex>

        <Skeleton
          height={24}
          width={340}
        />

        <Flex
          direction="column"
          gap={5}
        >
          <Skeleton
            height={12}
            width={369}
          />
          <Skeleton
            height={12}
            width={289}
          />
          <Skeleton
            height={12}
            width={340}
          />
        </Flex>

        <Space h={8} />

        <Flex gap={5}>
          <Skeleton
            height={12}
            width={48}
          />
          <Skeleton
            height={12}
            width={52}
          />
          <Skeleton
            height={12}
            width={32}
          />
          <Skeleton
            height={12}
            width={40}
          />
          <Skeleton
            height={12}
            width={64}
          />
        </Flex>

        <Flex
          justify="space-between"
          align="center"
        >
          <Flex gap={12}>
            <Skeleton
              height={24}
              width={48}
            />
            <Skeleton
              height={24}
              width={48}
            />
          </Flex>
          <Skeleton
            style={{ justifySelf: 'end' }}
            height={24}
            width={48}
          />
        </Flex>
      </Flex>

      <MantineSkeleton
        width={imageWidth}
        height={imageHeight}
        miw={imageWidth}
        mih={imageHeight}
      />
    </Flex>
  )
}
