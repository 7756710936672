import { Group } from '@mantine/core'

import { ThatchLink } from '~/components/shared/ThatchLink'

import { Typography } from '../shared/text/Typography'
import { footerOptions } from './footerLinks'

const SideFooter = () => {

  return (
    <Group spacing={8}>
      {footerOptions.map(({ label, redirectUrl }, idx) => (
        <ThatchLink
          key={`${label}-${idx}`}
          to={redirectUrl}
          underline={false}
          isOpenInNewWindow
        >
          <Typography
            variant="caption"
            color="appPlaceholder.0"
          >
            {label}
          </Typography>
        </ThatchLink>
      ))}
    </Group>
  )
}

export default SideFooter
