export const footerOptions:Array<{label: string, redirectUrl: string}> = [
    { label: 'Help', redirectUrl: 'https://thatch.notion.site/' },
    {
      label: 'Privacy',
      redirectUrl: 'https://thatch.notion.site/Privacy-Policy-408c99bc42c14705a1c8158d74ec5bbe',
    },
    {
      label: 'Terms',
      redirectUrl: 'https://thatch.notion.site/Terms-of-Service-3062a81446f64df0b4a3ddcd04531de6',
    },
    {
      label: 'Copyright',
      redirectUrl: 'https://thatch.notion.site/Copyright-Policy-30cdb522002b477aa62e4369e768b971',
    },
    { label: 'Become a Seller', redirectUrl: '/seller' },
    {
      label: 'Seller Academy',
      redirectUrl: 'https://thatch.notion.site/Seller-Academy-996bfd5e9d6443f2a58a955cd19aa7b4',
    },
    { label: 'About', redirectUrl: '/about' },
    { label: 'Careers', redirectUrl: 'https://angel.co/company/thatch-travel/jobs' },
    { label: 'Blog', redirectUrl: '/blog' },
    { label: 'Explore Places', redirectUrl: '/geo' },
  ]