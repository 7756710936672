import { useRouter } from 'next/router'
import { useState } from 'react'

import { InlineAuthView } from '~/components/auth/InlineAuthView'
import { ThatchButton, ThatchButtonProps } from '~/components/shared/ThatchButton'
import { notify } from '~/components/shared/notify'
import { useCurrentAuthState } from '~/context'
import { createBoard } from '~/endpoints/board'
import { captureSentryException } from '~/utils/sentry'

export const CreateGuideButton: React.FC<ThatchButtonProps> = props => {
  const { email } = useCurrentAuthState()
  const [isCreatingBoard, setIsCreatingBoard] = useState(false)
  const [showInlineAuth, setShowInlineAuth] = useState(false)
  const router = useRouter()

  const handleCreateNewGuide = async (isValid?: boolean) => {
    if (isValid || email) {
      setShowInlineAuth(false)
      setIsCreatingBoard(true)
      try {
        const newBoard = await createBoard()
        await router.push(`/guide/${newBoard.token}/edit`)
      } catch (error) {
        captureSentryException(error)
        notify(true, error.message)
      } finally {
        setIsCreatingBoard(false)
      }
    } else {
      setShowInlineAuth(true)
    }
  }

  return (
    <>
      <ThatchButton
        size={props.size ?? 'medium'}
        label="Create a guide"
        loading={isCreatingBoard}
        onClick={() => handleCreateNewGuide()}
        {...props}
      />

      {showInlineAuth && (
        <InlineAuthView
          launchSource="create_guide"
          onClose={() => setShowInlineAuth(false)}
          isOpened={showInlineAuth}
          initLandingView="login"
          onEmailAuthSuccess={() => handleCreateNewGuide(true)}
        />
      )}
    </>
  )
}
