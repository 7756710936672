import { Box, Flex, createStyles } from '@mantine/core'
import React from 'react'

import { CreateGuideButton } from '~/components/board/library/CreateGuideButton'
import { LocalImage } from '~/components/shared/image/LocalImage'
import { Typography } from '~/components/shared/text/Typography'
import { useScreenSize } from '~/hooks'

import { ThatchButton } from '../shared/ThatchButton'

import empty from 'public/images/illustrations/search-empty.png'

const FooterCard = ({
  title1,
  title2,
  btnLabel,
  isMobileScreen,
  isCreateGuide,
}: {
  title1: string
  title2: string
  btnLabel: string
  isMobileScreen: boolean
  isCreateGuide: boolean
}) => (
  <Flex
    p={isMobileScreen ? '32px 16px' : '32px 24px'}
    direction={isMobileScreen ? 'column' : 'row'}
    align={isMobileScreen ? 'start' : 'center'}
    justify="space-between"
    gap={16}
    sx={{ border: '1px solid rgba(0, 0, 0, 0.10)' }}
  >
    <Box>
      <Typography variant="body3">{title1}</Typography>
      <Typography variant="body3">{title2}</Typography>
    </Box>
    {isCreateGuide ? (
      <CreateGuideButton
        variant="outline"
        size="small"
        label={btnLabel}
      />
    ) : (
      <ThatchButton
        variant="outline"
        onClick={() =>
          window.open('https://qsejnqnzps1.typeform.com/to/sb93MiHI#source=serp', '_blank')
        }
        label={btnLabel}
      />
    )}
  </Flex>
)

const useStyle = createStyles(theme => ({
  container: {
    justifyContent: 'space-between',
    [`@media (max-width: ${theme.breakpoints.lg})`]: {
      justifyContent: 'center',
    },
  },
  leftWrapper: {
    maxWidth: 451,
    marginRight: 109,

    [`@media (max-width: ${theme.breakpoints.lg})`]: {
      width: '100%',
      maxWidth: '100%',
      marginRight: 0,
    },
  },
  image: {
    width: 240,
    height: 240,

    [`@media (max-width: ${theme.breakpoints.xs})`]: {
      width: 252,
      height: 252,
    },
  },
}))

export const EmptyState: React.FC<{ hideCreateGuide?: boolean }> = ({ hideCreateGuide }) => {
  const { classes } = useStyle()
  const { isMobileScreen } = useScreenSize()

  return (
    <Flex
      direction="column"
      justify="space-between"
      gap={isMobileScreen ? 0 : 60}
    >
      <Flex
        justify="space-between"
        align="center"
        direction={isMobileScreen ? 'column' : 'row'}
        py={isMobileScreen ? 48 : 40}
        gap={24}
      >
        <Flex
          direction="column"
          gap={isMobileScreen ? 8 : 16}
        >
          <Flex
            gap={4}
            justify={isMobileScreen ? 'center' : undefined}
          >
            <Typography
              variant="titleSerif"
              sx={isMobileScreen ? { fontSize: '20px !important' } : {}}
            >
              Couldn’t find
            </Typography>
            <Typography
              variant="titleSerifItalic"
              sx={isMobileScreen ? { fontSize: '20px !important' } : {}}
            >
              any matches...
            </Typography>
          </Flex>
          <Typography variant="body3">Try a new search or update your filters. </Typography>
        </Flex>

        <LocalImage
          className={classes.image}
          src={empty}
        />
      </Flex>

      <Flex
        direction="column"
        gap={16}
        mb={64}
      >
        <FooterCard
          title1="Still cant find what you’re looking for?"
          title2="Send us a request and we’ll do our best to help."
          btnLabel="Send a Request"
          isMobileScreen={isMobileScreen}
          isCreateGuide={false}
        />
        {!hideCreateGuide && (
          <FooterCard
            title1="People are searching for this destination a lot."
            title2="Be the first to have a guide for this search."
            btnLabel="Create a Guide"
            isMobileScreen={isMobileScreen}
            isCreateGuide={true}
          />
        )}
      </Flex>
    </Flex>
  )
}
